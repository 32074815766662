import React from "react";
import Logo from "../Assets/logo2.svg";
import { BsTwitter } from "react-icons/bs";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-wrapper">
      <div className="footer-section-one">
        <div className="footer-logo-container">
          <img src={Logo} alt="" />
        </div>
        <div className="footer-icons">
          <BsTwitter />
          <SiLinkedin />
          <BsYoutube />
          <FaFacebookF />
        </div>
      </div>
      <div className="footer-section-two">
        <div className="footer-section-columns">
          <Link style={{ textDecoration: "none" }} to="#Home">
            Home
          </Link>
          <Link to="#About"> About</Link>
          <Link to="#Testimonials"> Testimonials</Link>
          <Link to="#Contact"> Contact</Link>
        </div>
        <div className="footer-section-columns">
          <span>+91-7508811511</span>
          <span>Sondh Web Creators</span>
          <span>Railway Road, Tangra, Amritsar</span>
          <span> Punjab, India</span>
          <span>info@thecodeflix.com</span>
        </div>
        <div className="footer-section-columns">
          <Link to="/termsandconditions">
            <span>Terms & Conditions </span>
          </Link>
          <Link to="/privacypolicy">
            <span>Privacy Policy</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
